<template>
  <div v-if="!isExamResultLoading">
    <div class="_section-head">
      <div class="row align-content-end w-100">
        <div class="col-md-12">
          <span class="question-number">Question {{ questionNumber }} :</span>
          <button
            class="btn flag-btn flagged"
            v-if="flaggedQuestions.includes(currentQuestion.id)"
            @click="removeFlag(currentQuestion.id)"
          >
            Remove Flag
            <i class="fa fa-flag"></i>
          </button>
          <button
            v-else
            class="btn flag-btn"
            @click="addFlag(currentQuestion.id)"
          >
            Add Flag
            <i class="fa fa-flag"></i>
          </button>
          <div class="question-row row">
            <div class="col-md-9">
              <h5 class="question" v-html="currentQuestion.question"></h5>
            </div>
            <div
              class="
                col-md-3
                mark-col
                align-baseline align-text-bottom align-bottom
              "
            >
              <p class="t-right">{{ currentQuestion.mark }} mark</p>
              <p v-if="isSubmitted == true">
                Achieved Mark : {{ currentQuestion.achieved_mark }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="_section-body">
      <div class="row">
        <div class="col-md-9">
          <div class="answer-list">
            <ul class="list-unstyled">
              <li
                v-for="(option, optionIndex) in currentQuestion.answers"
                :key="option.key"
              >
                <a
                  href="javascript:void(0);"
                  v-if="isSubmitted == false"
                  :class="option.id === selectedAnswer ? 'selected-answer' : ''"
                  @click="
                    updateAnswer(option.program_exam_question_id, option.id)
                  "
                >
                  <span v-if="optionIndex === 0">A</span>
                  <span v-if="optionIndex === 1">B</span>
                  <span v-if="optionIndex === 2">C</span>
                  <span v-if="optionIndex === 3">D</span>
                </a>
                <a
                  href="javascript:void(0);"
                  v-else
                  :class="
                    option.id === currentQuestion.marked_answer &&
                    option.grade_percentage != 100
                      ? 'wrong-answer'
                      : option.id === currentQuestion.marked_answer
                      ? 'selected-answer'
                      : ''
                  "
                >
                  <span v-if="optionIndex === 0">A</span>
                  <span v-if="optionIndex === 1">B</span>
                  <span v-if="optionIndex === 2">C</span>
                  <span v-if="optionIndex === 3">D</span>
                </a>
                <p>
                  {{ option.option }}
                  <span
                    class="answer-active"
                    v-if="option.grade_percentage == 100"
                  >
                    « Correct answer</span
                  >
                </p>
                <div
                  class="long-feedback"
                  v-if="option.answer_feedback != NULL"
                >
                  <p v-if="currentQuestion.marked_answer == option.id">
                    <span class="subnote">
                      &#40;{{ option.answer_feedback }}&#41;
                    </span>
                  </p>
                </div>
              </li>
            </ul>
            <div
              class="feedback-wrapper"
              v-if="currentQuestion.general_feedback != NULL"
            >
              <h4>Feedback</h4>
              <div v-html="currentQuestion.general_feedback"></div>
            </div>
            <a
              class="btn clear-choice"
              href="#"
              v-if="attendedQuestion.includes(currentQuestion.id)"
              @click.prevent="clearAnswer(currentQuestion.id)"
            >
              <i class="fas fa-sync"></i>
              Clear my choice
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="question-indicator">
            <h6>Question Overview</h6>
            <ul class="list-unstyled">
              <li
                @click.prevent="questionNavigation(questionIndex)"
                v-for="(eachquestion, questionIndex) in questions"
                :key="questionIndex"
                :class="
                  attendedQuestion.includes(eachquestion.id)
                    ? 'active'
                    : eachquestion.id == currentQuestion.id
                    ? 'current'
                    : 'faded'
                "
              >
                <router-link
                  to="#"
                  @click.prevent="questionNavigation(questionIndex)"
                >
                  {{ questionIndex + 1 }}
                </router-link>
                <div
                  class="flag-label"
                  v-if="flaggedQuestions.includes(eachquestion.id)"
                >
                  <i class="fa fa-flag"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="button-wrap">
        <a
          href="#"
          @click="getPreviousQuestion"
          v-if="questionNumber > 1"
          class="block-cta btn"
          >Previous</a
        >
        <a
          href="#"
          @click="getNextQuestion"
          v-if="questionNumber < totalQuestions"
          class="block-cta btn"
          >Next</a
        >
      </div>
    </div>

    <div class="_section-footer btn-row text-right d-flex">
      <button
        href="javascript:void(0);"
        class="btn block-cta"
        @click="showAlert"
        :disabled="isSubmittingExam"
        v-if="isSubmitted == false"
        ref="examSubmitContainer"
      >
        <span v-if="!isSubmittingExam">Submit Exam</span>
        <span v-else>Submitting...</span>
      </button>
    </div>
  </div>
  <div class="result-dialogue" v-if="isExamResultLoading">
    <p>{{ resultMessage }}</p>
    <button class="btn clear-cta" @click="$router.go()">View Result</button>
  </div>
</template>

<script>
import ExamService from "@/services/ExamService";

export default {
  name: "ExamQuestion",
  props: ["questions", "isSubmitted"],
  data() {
    return {
      isSubmittingExam: false,
      isExamResultLoading: false,
      questionNumber: 0,
      questionIndex: 0,
      totalQuestions: 0,
      currentQuestion: "",
      selectedAnswer: 0,
      selectedAnswerArray: [],
      resultMessage: "",
      fullPage: true,
      attendedQuestion: [],
      flaggedQuestions: [],
    };
  },
  created() {
    this.updateTotalQuestions();
    this.getCurrentQuestion();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isThisQuestionAttended(question_id) {
      const selectedOptionIndex = this.selectedAnswerArray.findIndex(
        (question) => question.question_id === question_id
      );
      // returns an index if exists otherwise -1
      return selectedOptionIndex;
    },
    updateTotalQuestions() {
      this.totalQuestions = this.questions.length;
      // console.log(this.totalQuestions);
    },
    getCurrentQuestion() {
      this.questionNumber = this.questionIndex + 1;
      this.currentQuestion = this.questions[this.questionIndex];
      const selectedOptionIndex = this.isThisQuestionAttended(
        this.currentQuestion.id
      );
      if (selectedOptionIndex > -1) {
        this.selectedAnswer =
          this.selectedAnswerArray[selectedOptionIndex].answer_id;
      } else {
        this.selectedAnswer = -1;
      }
      // console.log(this.currentQuestion);
    },
    questionNavigation(index) {
      this.questionIndex = index;
      this.getCurrentQuestion();
    },
    getNextQuestion() {
      this.scrollToTop();
      if (this.questionNumber < this.totalQuestions) {
        this.questionIndex++;
        this.getCurrentQuestion();
      }
    },
    getPreviousQuestion() {
      this.scrollToTop();
      if (this.questionNumber > 1) {
        this.questionIndex--;
        this.getCurrentQuestion();
      }
    },
    updateAnswer(question_id, answer_id) {
      for (var i = 0; i < this.attendedQuestion.length; i++) {
        var item = this.attendedQuestion[i];
        if (item == question_id) {
          this.attendedQuestion.splice(i, 1);
        }
      }
      this.attendedQuestion.push(question_id);
      this.selectedAnswer = answer_id;
      const selectedOptionIndex = this.isThisQuestionAttended(question_id);
      if (selectedOptionIndex === -1) {
        const selectedOption = {
          question_id: question_id,
          answer_id: answer_id,
        };
        this.selectedAnswerArray.push(selectedOption);
      } else {
        this.selectedAnswerArray[selectedOptionIndex].answer_id = answer_id;
      }
      // console.log(this.selectedAnswerArray);
    },
    clearAnswer(questionId) {
      for (var i = 0; i < this.selectedAnswerArray.length; i++) {
        var item = this.selectedAnswerArray[i];
        if (item.question_id == questionId) {
          this.selectedAnswerArray.splice(i, 1);
        }
      }
      for (var j = 0; j < this.attendedQuestion.length; j++) {
        var selectedItem = this.attendedQuestion[j];
        if (selectedItem == questionId) {
          this.attendedQuestion.splice(j, 1);
        }
      }
      this.getCurrentQuestion();
    },
    showAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to attend this again!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Submit Exam!",
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.submitExam();
        }
      });
    },
    submitExam() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
      });

      let params = {
        exam_id: this.$route.params.exam_id,
        result: this.selectedAnswerArray,
      };
      // console.log(params);
      this.isSubmittingExam = true;
      ExamService.submitSubscribedProgramExamResult(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.isExamResultLoading = true;
            this.resultMessage = response.data.message;
            this.$emit("examSubmitted", true);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addFlag(questionId) {
      this.flaggedQuestions.push(questionId);
    },
    removeFlag(questionId) {
      for (var j = 0; j < this.flaggedQuestions.length; j++) {
        var selectedItem = this.flaggedQuestions[j];
        if (selectedItem == questionId) {
          this.flaggedQuestions.splice(j, 1);
        }
      }
    },
  },
};
</script>
