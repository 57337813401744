<template>
  <div class="exam-single">
    <div class="container">
      <div v-if="isExamLoading" class="loading">
        <circular-loader></circular-loader>
      </div>
      <div v-else-if="exam" class="head-section">
        <h5>{{ exam.title }}</h5>
        <p>Total Mark: {{ total_score }}</p>
        <p>Total Questions: {{ exam.total_questions }}</p>
        <p>Total Duration: {{ exam.duration }} Min</p>
        <p v-if="!isAlreadySubmitted">
          Count Down: {{ timeMin }}:{{ timeSec }}
        </p>
        <p v-if="isAlreadySubmitted == true">{{ message }}</p>
      </div>
      <div v-if="!isExamLoading" class="info-section">
        <div v-if="isQuestionsLoading" class="loading">Loading...</div>
        <ExamQuestion
          v-else
          :questions="questions"
          :isSubmitted="isAlreadySubmitted"
          @examSubmitted="examSubmittedAction()"
          ref="examQuestionComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExamService from "@/services/ExamService";
import ExamQuestion from "@/components/ExamQuestion.vue";
import CircularLoader from "@/components/loaders/CircularLoader.vue";

export default {
  name: "CourseExam",
  components: { ExamQuestion, CircularLoader },
  data() {
    return {
      isExamLoading: true,
      isQuestionsLoading: true,
      isAlreadySubmitted: false,
      total_score: 0,
      message: "",
      exam: null,
      questions: [],
      timerCount: null,
      timeMin: null,
      timeSec: null,
    };
  },
  created() {
    this.fetchExamDetails();
    this.fetchQuestions();
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && this.isAlreadySubmitted == false) {
          setTimeout(() => {
            if (this.timeSec == 0 && this.timeMin >= 0) {
              this.timeSec = 60;
              this.timeMin--;
            }
            if (this.timeSec > 0 && this.timeMin >= 0) {
              this.timeSec--;
            }
            this.timerCount--;
          }, 1000);
          if (this.timeMin == 1 && this.timeSec == 1) {
            this.$toast.info("You have 1 minute left!");
          }
        } else if (value == 0 && this.isAlreadySubmitted == false) {
          this.$refs.examQuestionComponent.submitExam();
        }
      },
      // immediate: true // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    fetchExamDetails() {
      let params = {
        exam_id: this.$route.params.exam_id,
        program_id: this.$route.params.program_id,
      };
      ExamService.getSubscribedProgramExamDetails(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.isExamLoading = false;
            this.exam = response.data.exam;
            this.timeMin = response.data.exam.duration;
            this.timeSec = 0;
            this.timerCount = this.timeMin * 60;
            this.total_score = response.data.total_score;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchQuestions() {
      let params = {
        exam_id: this.$route.params.exam_id,
        program_id: this.$route.params.program_id,
      };
      ExamService.getSubscribedProgramExamQuestions(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.isQuestionsLoading = false;
            this.questions = response.data.questions;
            if (response.data.is_attended == true) {
              this.isAlreadySubmitted = true;
              this.$emit("examSubmitted", true);
              this.message = response.data.message;
            }
          }
          if (response.data.status === "ERROR") {
            this.$emit("examSubmitted", true);
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    examSubmittedAction() {
      this.isAlreadySubmitted = true;
      this.$emit("examSubmitted", true);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/exam-single.scss";
</style>
